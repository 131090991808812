import { BUTTONS, LINKS } from "@/containers/Header/constants";
import { montserrat } from "@/utils/fonts";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Button } from "../Button";
import { Icon } from "../Icon";

type mobileNavProps = {
  isOpenMobileMenu: boolean;
  setIsOpenMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  openMobileSubMenu: string;
  setOpenMobileSubMenu: React.Dispatch<React.SetStateAction<string>>;
};

const MobileNav: React.FC<mobileNavProps> = ({
  isOpenMobileMenu,
  setIsOpenMobileMenu,
  openMobileSubMenu,
  setOpenMobileSubMenu,
}) => {
  return (
    <div
      className={clsx(
        montserrat.variable,
        `fixed flex transition ease-in-out duration-300  bg-white overflow-hidden z-50 `,
        isOpenMobileMenu
          ? `w-[100dvw] h-[100dvh] translate-x-0`
          : `h-screen w-screen translate-x-full`
      )}
    >
      <div className="w-full flex flex-col m-4 justify-between">
        <div className="flex flex-col justify-between pb-8">
          {/* Logo and close mobile nav icon*/}
          <div className="flex flex-row justify-between pb-8">
            <Image
              src={"/images/mintology-logo-inverted.webp"}
              alt="Mobile menu logo"
              width={117}
              height={18}
            />
            <button
              onClick={() => setIsOpenMobileMenu(false)}
              className=""
              aria-label="Close"
            >
              <Icon name="close" size="lg" className="text-neutral-900" />
            </button>
          </div>

          <div className="left-0 bg-white mx-4">
            <ul className="text-primary-600 flex flex-col gap-2 text-left w-full">
              {LINKS.map((link, i) =>
                //is Dropdown & has children
                link.children ? (
                  <li key={link.title + i + "Mobile"} className="flex flex-col">
                    <button
                      onClick={() => setOpenMobileSubMenu(link.title)}
                      aria-label={link.title}
                    >
                      <div className="flex flex-row justify-between w-full">
                        <span className="py-2 font-montserrat font-semibold">
                          {link.title}
                        </span>
                        <Icon name="right-arrow" />
                      </div>
                    </button>
                    {openMobileSubMenu === link.title ? (
                      <div
                        className={clsx(
                          `h-3/4 w-full absolute top-20 bg-white flex flex-col -m-4`
                        )}
                      >
                        <button
                          onClick={() => setOpenMobileSubMenu("")}
                          className="py-2"
                          aria-label="Back"
                        >
                          <div className="flex flex-row justify-start pb-2">
                            <Icon
                              name="right-arrow"
                              className="rotate-180 px-2 mr-4"
                            />
                            <span>Back</span>
                          </div>
                        </button>
                        <div className="flex flex-col px-3">
                          {link.children.map((sublink, i) => (
                            <Link
                              key={sublink.title + i + "Mobile"}
                              href={sublink.url}
                              target={sublink.newTab ? "_blank" : undefined}
                              className={clsx(
                                "py-3 font-montserrat font-semibold"
                              )}
                            >
                              {sublink.title}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </li>
                ) : (
                  //is a clickable link
                  <li
                    key={link.title + i + "Mobile"}
                    className="flex justify-start"
                  >
                    <Link
                      href={link.url}
                      className={clsx(
                        "py-2 font-montserrat font-semibold w-full"
                      )}
                      target={link.newTab ? "_blank" : undefined}
                      onClick={() => setIsOpenMobileMenu(false)}
                    >
                      {link.title}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {/* Buttons at the bottom */}
          {BUTTONS.map((link, i) => (
            <Button
              key={link.title + i + "Mobile"}
              onClick={() => {
                window.open(link.url, "_blank");
              }}
              className="mx-4"
              aria-label={link.title}
            >
              {link.title}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
