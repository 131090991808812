export enum AnimationsEnum {
  FadeInUp = "FadeInUp",
  FadeInRight = "FadeInRight",
  FadeInLeft = "FadeInLeft",
  FadeInDown = "FadeInDown",
  FadeIn = "FadeIn",
  FadeOutUp = "FadeOutUp",
}

export type AnimatedWrapperProps = {
  tag?: any;
  children?: string | JSX.Element | JSX.Element[];
  animationType?: AnimationsEnum;
  className?: string;
  duration?: number;
  delay?: number;
};
