// https://www.figma.com/file/QgliSnRPM2xRhsM8F7J0cp/Mintology2023-UI-(-Self-serve-)?node-id=7234-4006&t=yQwOZPWzC004JCob-0

import { ButtonSize, ButtonVariants } from "./types";

export const variantStyle: { [key in ButtonVariants]: string } = {
  primary: `
    bg-primary-900 
    text-white 
    border 
    border-primary-900
    hover:bg-[#5D0FC3]
    hover:text-white
    hover:border-[#5D0FC3]
    disabled:bg-[#F7F7F7] 
    disabled:border-[#F7F7F7] 
    disabled:text-[#949494] 
    disabled:cursor-not-allowed 
  `,
  secondary: `
    bg-secondary-700
    text-white 
    border 
    border-secondary-700
    hover:bg-secondary-300
    hover:border-secondary-900
    disabled:bg-transparent 
    disabled:border-[#CFCFCF] 
    disabled:text-[#CFCFCF] 
    disabled:cursor-not-allowed 
  `,
  tertiary: `
    bg-white
    text-primary-900 
    border-2
    border-primary-900 
    hover:bg-[#EFEFEF]
    hover:text-secondary-900 
    hover:border-[#D2D2D2] 
    disabled:bg-[#FAFAFA] 
    disabled:border-[#D2D2D2] 
    disabled:text-[#CFCFCF] 
    disabled:cursor-not-allowed 
  `,
  text: `
    bg-transparent
    text-primary-900 
    hover:text-primary-900
    hover:border-neutral-900
    disabled:text-[#CFCFCF] 
    disabled:cursor-not-allowed 
  `,
};

export const sizeStyle: { [key in ButtonSize]: string } = {
  xs: "px-[0.5rem] py-[0.3rem] text-[0.8rem]",
  sm: "px-[1.25rem] py-[0.65rem] text-[1rem]",
  md: "px-[2.625rem] py-[0.625rem] text-[1.125rem]",
  lg: "px-[2.875rem] py-[1rem] text-[1.125rem]",
};

export const buttonIconSizeStyle: { [key in ButtonSize]: string } = {
  xs: "!p-1.5 text-xs",
  sm: "!p-2.5 text-sm",
  md: "!p-3 text-base",
  lg: "!p-3.5 text-lg",
};

export const loaderSizeStyle: { [key in ButtonSize]: string } = {
  xs: "h-3 w-3,",
  sm: "h-5 w-5,",
  md: "h-6 w-6",
  lg: "h-7 w-7",
};
