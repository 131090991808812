import React from "react";

import { createDomMotionComponent } from "framer-motion";

import { AnimatedWrapperProps, AnimationsEnum } from "./types";

const AnimatedWrapper: React.FC<AnimatedWrapperProps> = ({
  tag = "div",
  children,
  animationType = AnimationsEnum.FadeInUp,
  className,
  duration = 0.5,
  delay,
}) => {
  const settings = () => {
    let animation = null;

    switch (animationType) {
      case AnimationsEnum.FadeInRight:
        animation = {
          variants: {
            initial: {
              opacity: 0,
              x: "15px",
            },
            animate: {
              opacity: 1,
              x: "0",
            },
          },
        };
        break;
      case AnimationsEnum.FadeInLeft:
        animation = {
          variants: {
            initial: {
              opacity: 0,
              x: "-45px",
            },
            animate: {
              opacity: 1,
              x: "0",
            },
          },
        };
        break;
      case AnimationsEnum.FadeInDown:
        animation = {
          variants: {
            initial: {
              opacity: 0,
              y: "-45px",
            },
            animate: {
              opacity: 1,
              y: "0",
            },
          },
        };
        break;
      case AnimationsEnum.FadeInUp: // FadeInUp
        animation = {
          variants: {
            initial: {
              opacity: 0,
              y: "45px",
            },
            animate: {
              opacity: 1,
              y: "0",
            },
          },
        };
        break;
      case AnimationsEnum.FadeOutUp: // FadeInUp
        animation = {
          variants: {
            initial: {
              opacity: 1,
              y: "0px",
            },
            animate: {
              opacity: 0,
              y: "-45px",
            },
          },
        };
        break;
      default: // fade in up
        animation = {
          variants: {
            initial: {
              opacity: 0,
            },
            animate: {
              opacity: 1,
            },
          },
        };
    }

    return {
      ...animation,
      transition: { duration, delay: delay ?? 0 },
      initial: "initial",
      whileInView: "animate",
      viewport: { once: true },
    };
  };
  const motion = {
    div: createDomMotionComponent(tag),
  };
  return (
    <motion.div {...settings()} className={className}>
      {children}
    </motion.div>
  );
};

export default AnimatedWrapper;
