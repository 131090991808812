import { env } from "@/config/env";
import { montserrat } from "@/utils/fonts";
import clsx from "clsx";
import Link from "next/link";
import { useEffect, useState } from "react";

type Props = {
  isHidden: boolean;
};

const numberOfBalls = 50;

export function PolitCampaignBanner({ isHidden }: Props) {
  const [balls, setBalls] = useState<any>([]);

  useEffect(() => {
    const container = document.getElementById("promotion-container");
    if (!container) {
      return;
    }

    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    const createAnimation = () => {
      const newBalls = [];
      for (let i = 0; i < numberOfBalls; i++) {
        const randomSize = 2 + Math.random() * 5; // Random size between 10px and 30px
        const randomX = Math.random() * (containerWidth - randomSize);
        const randomY = Math.random() * (containerHeight - randomSize);

        const randomDelay = Math.random() * 2; // Random delay to start the animation at different times
        const randomDuration = 3 + Math.random() * 4; // Random duration between 1 and 3 seconds
        const randomOpacity = 0.5 + Math.random() * 0.5; // Random opacity between 0.5 and 1

        newBalls.push({
          size: randomSize,
          left: randomX,
          top: randomY,
          delay: randomDelay,
          duration: randomDuration,
          opacity: randomOpacity,
        });
      }

      setBalls(newBalls);
    };

    createAnimation();
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <header
      id="promotion-container"
      className={clsx(
        montserrat.variable,
        "w-full overflow-hidden bg-[#00D0C6] gap-2 py-2 px-4 text-white flex flex-col items-center justify-center font-montserrat lg:flex-row  lg:py-3 relative"
      )}
    >
      <div className="absolute w-full h-full flex overflow-hidden">
        <div className="relative flex-1 ">
          <div className="night inset-0">
            <div className="star" />
            <div className="star" />
            <div className="star" />
            <div className="star" />
          </div>
        </div>

        <div className="relative flex-1 ">
          <div className="night inset-0">
            <div className="star" />
            <div className="star" />
            <div className="star" />
            <div className="star" />
          </div>
        </div>

        <div className="relative flex-1 ">
          <div className="night inset-0">
            <div className="star" />
            <div className="star" />
            <div className="star" />
            <div className="star" />
          </div>
        </div>
      </div>

      {balls.map((ball: any, index: number) => (
        <div
          key={index}
          className="absolute rounded-full bg-[#5FF6F6]"
          style={{
            width: `${ball.size}px`,
            height: `${ball.size}px`,
            left: `${ball.left}px`,
            top: `${ball.top}px`,
            animation: `blinkMove ${ball.duration}s infinite`,
            animationDelay: `${ball.delay}s`,
            opacity: ball.opacity,
          }}
        />
      ))}

      <h2 className="font-bold text-center lg:text-lg z-10">
        Sign Up Now for up to $7,000 in Free Credits!
      </h2>

      <Link
        href={env.DASHBOARD_URL}
        className="z-10 flex items-center rounded-md bg-[#8937F4] px-3 py-1 text-white hover:bg-[#A15FF6] hover:text-white disabled:opacity-50"
      >
        <div className="ml-1">Activate Now</div>
      </Link>
    </header>
  );
}
