import { Env } from "@/schemas/Env";

import { dev } from "./environments/dev";
import { prod } from "./environments/prod";

export const GA_MEASUREMENT_ID = "G-277GYZEKQN"; // .app
export const GTM_ID = "GTM-MGVMG2NF";

export const environments: Record<string, Env> = { prod, dev };

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || "prod";
export const IS_PROD_BUILD = process.env.NODE_ENV === "production";

const name = IS_PROD_BUILD ? APP_ENV : "dev";

export const env = environments[name] || prod;

export const FORMSPREE_FORM_ID = "xzblrpgg";

export const MINTOLOGY_API_KEY = "";

export const WHITELISTING_ENABLED =
  Boolean(process.env.NEXT_PUBLIC_WHITELISTING_ENABLED) &&
  process.env.NEXT_PUBLIC_WHITELISTING_ENABLED !== "false";
