// @link - https://dev.to/gabrielmlinassi/a-more-stylish-way-to-write-conditional-tailwind-classes-5ae6
import clsx from "clsx";
import { useRef, useState } from "react";

// import { Icon } from "../Icon";
import { sizeStyle, variantStyle } from "./constants";
import { ButtonProps } from "./types";

/**
 * Button component
 */
export const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  size = "md",
  isLoading,
  isFullWidth,
  className,
  children,
  rounded,
  iconClassName,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonState, setButtonState] = useState<{
    height: number;
    width: number;
  } | null>(null);

  const btnStyle = isLoading
    ? { width: `${buttonState?.width}px`, height: `${buttonState?.height}px` }
    : {};

  return (
    <button
      ref={buttonRef}
      style={btnStyle}
      className={clsx(
        "inline-flex items-center justify-center gap-2 rounded-lg text-base leading-4",
        variantStyle[variant],
        sizeStyle[size],
        isFullWidth && "w-full",
        className,
        { "!rounded-full": rounded }
      )}
      {...props}
    >
      {children}
    </button>
  );
};
