import clsx from "clsx";

import { iconStyle, sizeStyle } from "./constants";
import { IconProps } from "./types";

export const Icon: React.FC<IconProps> = ({
  className,
  innerClassName,
  name,
  size = "md",
  isMask = true,
  sizing = "contain",
  rounded,
  ...props
}) => {
  const path = `/icons/${name}.svg`;

  return (
    <div
      className={clsx(className, "h-fit w-fit !shrink-0 !self-center", {
        "rounded-full bg-neutral-600": rounded,
      })}
    >
      <span
        className={clsx(
          "block !shrink-0 !self-center align-middle",
          innerClassName,
          sizeStyle[size],
          {
            "!m-0": !rounded,
          }
        )}
        style={iconStyle(path, isMask, sizing)}
        {...props}
      />
    </div>
  );
};
