export const externalLinks = {
  documentation: "https://docs.mintology.app/",
  demo: "https://demo.mintology.app/",
  dashboard: "https://dashboard.mintology.app",
  pricing: "https://mintology.app/pricing",
  blog: "https://blog.mintology.app/",
  privacyPolicy: "/privacy",
  termsOfService: "/terms",
  billingPage: "https://dashboard.mintology.dev/billing",
  awsMarketplace:
    "https://aws.amazon.com/marketplace/pp/prodview-s4ptm7xqvloks?sr=0-1&ref_=beagle&applicationId=AWSMPContessa",
  awsPromoVideo:
    "https://mintology-dev-storage.s3.ap-southeast-1.amazonaws.com/aws-marketplace-assets/AWS-Final-01.mp4",
  wordpressLink: "https://cdn.mintology.app/wordpress/mintology-wordpress-plugin.zip",
};
