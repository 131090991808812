import { IconSize } from "./types";

export const IconNames = [
  "arrow-circle-right",
  "arrow-diagonal",
  "buildings-fill",
  "check-fill",
  "check-outlined",
  "checkmark-shield",
  "chevron-down",
  "close",
  "custodial-wallets",
  "discord",
  "document-duplicate",
  "download",
  "game-controller",
  "icon-info",
  "icon-outline-plus",
  "icon-outline-star",
  "icon-outline-tick",
  "inbox-outlined",
  "instagram",
  "in-depth-analytics",
  "linkedin",
  "loader-outlined",
  "mail",
  "membership",
  "merchant-app",
  "minus-outlined",
  "nft-api-suite",
  "plus-outlined",
  "right-arrow",
  "sales-outlined",
  "smart-contract-generator",
  "star-outlined",
  "tech-outlined",
  "ticket-stub",
  "virus",
  "wordpress-plugins",
  "x",
] as const;

export const sizeStyle: { [key in IconSize]: string } = {
  xs: "m-[2px] w-[0.5rem] h-[0.5rem]",
  sm: "m-[2px] w-[0.75rem] h-[0.75rem]",
  md: "m-[2px] w-[1rem] h-[1rem]",
  lg: "m-[2px] w-[1.25rem] h-[1.25rem]",
  xl: "m-[2px] w-[1.5rem] h-[1.5rem]",
  "2xl": "m-[2px] w-[1.75rem] h-[1.75rem]",
};

export const iconStyle = (
  src: string,
  isMask: boolean,
  sizing: string
): React.CSSProperties => {
  return isMask
    ? {
        maskRepeat: "no-repeat",
        WebkitMaskRepeat: "no-repeat",
        WebkitMaskImage: `url(${src})`,
        maskImage: `url(${src})`,
        maskSize: sizing,
        backgroundRepeat: "no-repeat",
        WebkitMaskSize: sizing,
        maskPosition: "center",
        backgroundColor: "currentColor",
        WebkitMaskPosition: "center center",
      }
    : {
        backgroundImage: `url(${src})`,
        backgroundColor: "unset",
        backgroundSize: sizing,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      };
};
