import { Env } from "@/schemas/Env";

export const prod: Env = {
  APP_DOMAIN: "mintology.app",
  WAITLIST_URL: "https://dashboard.mintology.app/",
  API_URL: "https://api.mintology.app/v1",
  DOCUMENTATION_URL: "https://docs.mintology.app",
  AWS_REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_CCxozvpC4",
  OAUTH_DOMAIN: "",
  STRIPE_CLIENT_PK: "",
  USER_POOL_CLIENT_ID: "20d2cr33peg46v06gi8542nfgm",
  GOOGLE_RECAPTCHA_SITE_KEY: "",
  HEAP_ANALYTICS_ID: "",
  BANNER_ENABLED: false,
  DASHBOARD_URL: "https://dashboard.mintology.app",
  DASHBOARD_LOG_IN_URL: "https://dashboard.mintology.app/signin",
  DASHBOARD_SIGN_UP_URL: "https://dashboard.mintology.app/signup",
  PRICING_URL: "https://dashboard.mintology.app/billing",
  MINTOLOGY_KEY: process.env.MINTOLOGY_KEY || "example-key",
};
