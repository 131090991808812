import { Env } from "@/schemas/Env";

export const dev: Env = {
  APP_DOMAIN: "mintology.dev",
  API_URL: "https://api.mintology.dev",
  WAITLIST_URL: "https://dashboard.mintology.dev",
  DOCUMENTATION_URL: "https://docs.mintology.app",
  AWS_REGION: "ap-southeast-1",
  USER_POOL_ID: "ap-southeast-1_NkUCBxvOy",
  USER_POOL_CLIENT_ID: "138qseftrqp8ca1ficmi5ev6nv",
  OAUTH_DOMAIN: "mintology-dev-idp.auth.ap-southeast-1.amazoncognito.com",
  STRIPE_CLIENT_PK:
    "pk_test_51NAlrGEoiPT89ygTyGsjmCQvolXmmTr8UL2Ce9XohOzIEQiPJaUlajf6mD61v6aeuIj1KRSOmH22hFkQ8tKNTCUE00heznuraU",
  GOOGLE_RECAPTCHA_SITE_KEY: "6LdRCtMdAAAAADGhLp4ejtzKo4oWXQg1c_CuMMPy",
  HEAP_ANALYTICS_ID: "2738921819",
  BANNER_ENABLED: true,
  DASHBOARD_URL: "https://dashboard.mintology.dev",
  DASHBOARD_LOG_IN_URL: "https://dashboard.mintology.dev/signin",
  DASHBOARD_SIGN_UP_URL: "https://dashboard.mintology.dev/signup",
  PRICING_URL: "https://dashboard.mintology.dev/billing",
  MINTOLOGY_KEY: process.env.MINTOLOGY_KEY || "example-key",
};
